export const BACKEND_URL='https://qc2.hpcfuelrite.com';

export const gasolineCodes = [
    { name: 'B02' ,checked:false},
    { name: 'F07' ,checked:false},
    { name: 'A30' ,checked:false},
    { name: 'A99' ,checked:false},
    { name: 'M10' ,checked:false},
    { name: 'M11' ,checked:false},
    { name: 'M12' ,checked:false},
    { name: 'M13' ,checked:false},
    { name: 'M14' ,checked:false},
    { name: 'A98' ,checked:false},
    { name: 'A00' ,checked:false},
    { name: 'A01' ,checked:false},
    { name: 'A09' ,checked:false},
    { name: 'U02' ,checked:false},
    { name: 'A10' ,checked:false},
    { name: 'A11' ,checked:false},
    { name: 'A50' ,checked:false},
    { name: 'U01' ,checked:false},
    { name: 'A51' ,checked:false},
    { name: 'A52' ,checked:false},
    { name: 'A53' ,checked:false},
    { name: 'A54' ,checked:false},
    { name: 'F00' ,checked:false},
    { name: 'A55' ,checked:false},
    { name: 'A61' ,checked:false},
    { name: 'A63' ,checked:false},
    { name: 'A64' ,checked:false},
    { name: 'A65' ,checked:false},
    { name: 'A66' ,checked:false},
    { name: 'F01' ,checked:false},
    { name: 'A67' ,checked:false},
    { name: 'A62' ,checked:false},
    { name: 'F26' ,checked:false},
    { name: 'F27' ,checked:false},
    { name: 'F31' ,checked:false},
    { name: 'F25' ,checked:false},
    { name: 'U08' ,checked:false},
    { name: 'A68' ,checked:false},
    { name: 'A69' ,checked:false},
    { name: 'U06' ,checked:false},
    { name: 'U07' ,checked:false},
    { name: 'A56' ,checked:false},
    { name: 'B03' ,checked:false},
    { name: 'C20' ,checked:false},
    { name: 'C22' ,checked:false},
    { name: 'C23' ,checked:false},
    { name: 'C24' ,checked:false},
    { name: 'C25' ,checked:false},
    { name: 'C26' ,checked:false},
    { name: 'C27' ,checked:false},
    { name: 'E-85',checked:false },
    { name: 'M85' ,checked:false},
    { name: 'FUL' ,checked:false},
    { name: 'FUL' ,checked:false},
    { name: 'ETH' ,checked:false},
    { name: 'ETH' ,checked:false},
    { name: 'SUC' ,checked:false},
    { name: 'UNL' ,checked:false},
    { name: 'SUP' ,checked:false},
    { name: 'ETH' ,checked:false},
    { name: 'ETH' ,checked:false},
    { name: 'ETH' ,checked:false},
    { name: 'ETH' ,checked:false},
    { name: 'UNC' ,checked:false},
    { name: 'UN+' ,checked:false},
    { name: 'ETH' ,checked:false},
    { name: 'UNL' ,checked:false},
    { name: 'SUP' ,checked:false},
];



export const dieselCodes = [
    { name: 'D72' ,checked:false},
    { name: 'D75' ,checked:false},
    { name: 'D73' ,checked:false},
    { name: 'D91' ,checked:false},
    { name: 'D76' ,checked:false},
    { name: 'D85' ,checked:false},
    { name: 'D86' ,checked:false},
    { name: 'A27' ,checked:false},
    { name: 'D93' ,checked:false},
    { name: 'D74' ,checked:false},
    { name: 'A02' ,checked:false},
    { name: 'A57' ,checked:false},
    { name: 'A16' ,checked:false},
    { name: 'A18' ,checked:false},
    { name: 'A08' ,checked:false},
    { name: 'F10' ,checked:false},
    { name: 'F11' ,checked:false},
    { name: 'F12' ,checked:false},
    { name: 'F13' ,checked:false},
    { name: 'F14' ,checked:false},
    { name: 'F15' ,checked:false},
    { name: 'F16' ,checked:false},
    { name: 'D45' ,checked:false},
    { name: 'D46' ,checked:false},
    { name: 'D47' ,checked:false},
    { name: 'D48' ,checked:false},
    { name: 'D49' ,checked:false},
    { name: 'D51' ,checked:false},
    { name: 'D52' ,checked:false},
    { name: 'D62' ,checked:false},
    { name: 'D63' ,checked:false},
    { name: 'D64' ,checked:false},
    { name: 'D53' ,checked:false},
    { name: 'D60' ,checked:false},
    { name: 'D50' ,checked:false},
    { name: 'D78' ,checked:false},
    { name: 'D80' ,checked:false},
    { name: 'D82' ,checked:false},
    { name: 'D84' ,checked:false},
    { name: 'A28' ,checked:false},
    { name: 'D79' ,checked:false},
    { name: 'D81' ,checked:false},
    { name: 'D83' ,checked:false},
    { name: 'D87' ,checked:false},
    { name: 'D57' ,checked:false},
    { name: 'D68' ,checked:false},
    { name: 'D95' ,checked:false},
    { name: 'D88' ,checked:false},
    { name: 'D97' ,checked:false},
    { name: 'A29' ,checked:false},
    { name: 'D77' ,checked:false},
    { name: 'D90' ,checked:false},
    { name: 'D94' ,checked:false},
    { name: 'F04' ,checked:false},
    { name: 'F32' ,checked:false},
    { name: 'F33' ,checked:false},
    { name: 'F34' ,checked:false},
    { name: 'F35' ,checked:false},
    { name: 'F17' ,checked:false},
    { name: 'F18' ,checked:false},
    { name: 'F19' ,checked:false},
    { name: 'F20' ,checked:false},
    { name: 'F21' ,checked:false},
    { name: 'F22' ,checked:false},
    { name: 'F23' ,checked:false},
    { name: 'D54' ,checked:false},
    { name: 'D55' ,checked:false},
    { name: 'D65' ,checked:false},
    { name: 'D66' ,checked:false},
    { name: 'D67' ,checked:false},
    { name: 'D56' ,checked:false},
    { name: 'D61' ,checked:false},
    { name: 'D71' ,checked:false},
    { name: 'F03' ,checked:false},
    { name: 'D89' ,checked:false},
    { name: 'D59' ,checked:false},
    { name: 'D70' ,checked:false},
    { name: 'D96' ,checked:false},
    { name: 'F02' ,checked:false},
    { name: 'D58' ,checked:false},
    { name: 'D69' ,checked:false},
    { name: 'D92' ,checked:false},
    { name: 'D98' ,checked:false},
    { name: 'F24' ,checked:false},
    { name: 'D99' ,checked:false},
    { name: 'A17' ,checked:false},
    { name: 'A03' ,checked:false},
    { name: 'A13' ,checked:false},
    { name: 'F08' ,checked:false},
    { name: 'F09' ,checked:false},
    { name: 'F05' ,checked:false},
];

export const aviationFuel = [
    { name: 'B82',checked:false },
    { name: 'B81',checked:false },
    { name: 'A97',checked:false },
    { name: 'A95',checked:false },
    { name: 'B83',checked:false },
    { name: 'B84',checked:false },
    { name: 'B88',checked:false },
    { name: 'A96',checked:false },
    { name: 'B85',checked:false },
]

export const LPGCodes = [
    { name: 'A60',checked:false },
    { name: 'A59',checked:false },
    { name: 'B01',checked:false },
    { name: 'F06',checked:false },
]
